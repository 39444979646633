import { defineStore } from 'pinia'

export const useSearchStore = defineStore({
    id: 'searchStore',
    state: () => ({
        searchValue: '',
        currentPage: 1,
        filterIds: [],
        typeId: null,
        statusId: null,
        locationId: null,
        priceRange: [],
        areaRange: [],
        roomRange: [],
        currentNewsType: "",
    }),
    

})